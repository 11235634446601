<template>
    <div class="order-preview-page my-5">
        <div class="container mb-5">
            <h1>Szczegóły zamówienia <span v-if="order">nr {{ order.orderID }}</span></h1>

            <div class="mb-3 text-right">
               <router-link :to="{name: 'CustomerAccount'}" >Powrót</router-link>
            </div>

            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
            <div v-else>
                <div v-if="order">
                    <h3>Zamównienie złożone dnia: </h3>
                    <div>{{ order.place_order_time | formatDateTime }}</div>

                    <orderDetails :order="order" class="mt-4" />

                    <h3 class="mt-5 text-right">Razem do zapłaty: <span class="product-sum">{{ Number(order.sumPrice).toLocaleString() }} zł</span></h3>
                    <div v-if="isUnpaid && canPay">
                        <div class="text-center mt-3 mb-5">
                            <button class="btn btn-success" @click.prevent="goToPayment()" >Potwierdzam i płacę</button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    Brak zamówienia
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';

moment.locale('pl');

import { SCHEDULE_statusToPay } from "@/constants/constants.js";
import OrderDetails from '@/components/order/OrderDetails.vue';

export default {
    name: "OrderPreview",
    components: {
        OrderDetails
    },
    data() {
        return {
            isLoading: true,
            order: null
        }
    },
    mounted(){
        this.$store.dispatch('order/getOrderInfo', this.$route.params.orderId ).then( (data) => {
            this.order = data;
            this.isLoading = false;
        }).catch( () => {
                    this.isLoading = false;
                });
    },
    computed: {
        isUnpaid() {
            return this.order && this.order.status == SCHEDULE_statusToPay ? true : false;
        },
        canPay() {
            return this.order && moment(moment()).isSameOrBefore(moment(this.order.dateStart)) ? true : false;
        }
    },
    methods: {
        goToPayment() {
            this.isLoading = true;
            this.$router.push( {name: 'OrderPayment', params: { orderId: this.$route.params.orderId } } );
        }
    }
};
</script>
