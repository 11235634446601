<template>
    <div v-if="order">
        <h3>Rodzaj dostawy: </h3>
        <div>{{ scheduleTypeName }}</div>

        <h3 class="mt-3">Adres dostawy: </h3>
        <div class="d-flex">
            <div class="mr-5">
                {{ order.address.name }} {{ order.address.surname }}, 
                <b>{{ order.address.street }} {{ order.address.number }},
                {{ order.address.postcode }} {{ order.address.city }}</b>
                <br />
                tel. <b>{{ order.address.phone }}</b>
            </div>
            <div v-if="order.address.comment" class="d-flex justify-content-between" >
                <div class="mr-3">uwagi:</div> 
                <div><b><span class="nl2br" v-html="order.address.comment"></span></b></div>
            </div>
        </div>

        <div v-if="countDeliveries() > 1">
            <h3 class="mt-3">Termin dostawy: </h3>
            <div v-if="isSubscription">
                Rozpoczęcie dostaw: <b>{{ order.dateStart | formatDate }}</b>, 
                Dostawy opłacone do: <b>{{ order.dateEnd | formatDate }}</b>
            </div>
            <div v-else>
                <b>{{ order.dateStart | formatDate }}</b>
            </div>
            <h3 class="mt-3">Dostaw: {{ countDeliveries() }} </h3>
        </div>
        <h3 class="mt-3" v-if="countDeliveries() == 1">Dostawa:</h3>
        <div class="container-fluid">
            <div v-for="deliveryDate in order.deliveryDates" :key="deliveryDate.date" :class="{omitted: deliveryDate.omitted }" 
                class="order-summary-day-box row mt-3">
                <div class="col-12 col-md-3 date-header">                    
                    <div class="row">
                        <div class="col-4 col-md-12">{{ moment(deliveryDate.date).format('LL') }}</div>
                        <div class="col-4 col-md-12">{{ moment(deliveryDate.date).format('dddd') }}</div>
                        <div class="col-4 col-md-12">
                            <span v-if="deliveryStatus(deliveryDate) == 1" class="badge badge-success">{{ deliveryStatusLabel(deliveryStatus(deliveryDate)) }}</span>
                            <span v-else class="badge badge-warning">{{ deliveryStatusLabel(deliveryStatus(deliveryDate)) }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-9 products p-0">
                    <div v-if="deliveryDate.omitted" class="p-2">Nie prowadzimy dostaw.</div>
                    <div v-else>
                        <div v-for="deliveryProduct in deliveryDate.products" :key="deliveryProduct.product.id" class="product-box d-flex flex-wrap">
                            <div class="product-name">{{ deliveryProduct.product.name }}</div>
                            <div class="product-price">{{ Number(deliveryProduct.product.price).toLocaleString() }} zł/szt</div>
                            <div class="product-amount">{{ deliveryProduct.amount }} szt</div>
                            <div class="product-sum"><small>razem:</small> {{ (deliveryProduct.amount * deliveryProduct.product.price).toLocaleString() }} zł</div>
                            <div class="delivery-status" v-if="showDeliveryStatus">{{ deliveryStatusLabel( deliveryProduct.status ) }}</div>
                        </div> 
                        <div class="product-box d-flex" v-if="!showDeliveryStatus" >
                            <div class="product-name">Dostawa</div>
                            <div class="product-price">{{ Number(order.deliveryPrice).toLocaleString() }} zł</div>
                            <div class="product-amount">{{ 1 }} szt</div>
                            <div class="product-sum"><small>razem:</small> {{ Number(order.deliveryPrice).toLocaleString() }} zł</div>
                        </div> 
                        <div class="product-box d-flex" v-if="!showDeliveryStatus"  >
                            <div class="product-name">Opakowanie</div>
                            <div class="product-price">{{ Number(order.boxPrice).toLocaleString() }} zł</div>
                            <div class="product-amount">{{ 1 }} szt</div>
                            <div class="product-sum"><small>razem:</small> {{ Number(order.boxPrice).toLocaleString() }} zł</div>
                        </div> 
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div v-else>
        brak dostępu
    </div>
</template>

<script>
import moment from 'moment'
moment.locale('pl');

import {SCHEDULE_typeSubscription, DELIVERY_DATE_statusPaid, DELIVERY_DATE_statusDelivered, 
        DELIVERY_DATE_statusDeliveryFailed, DELIVERY_DATE_statusCanceled, } from "@/constants/constants.js"; 

export default {
    name: "orderDetails",
    props: {
        order: {
            type: Object,
            required: true,
        },
        showDeliveryStatus: {
            type: Boolean,
            default: false
        },
    },
    created: function () {
        this.moment = moment;
    },
    computed: {
        scheduleTypeName: function () {
            return this.order.scheduleType == SCHEDULE_typeSubscription ? 'Abonament miesięczny' : 'Jednorazowa dostawa';
        },
        isSubscription( ) {
            return this.order.scheduleType == SCHEDULE_typeSubscription
        },
    },
    methods: {
        deliveryStatusLabel(status) {
            let label = '';
            switch( status ){
                case DELIVERY_DATE_statusPaid: { label = 'Zaplanowana'; break; }
                case DELIVERY_DATE_statusDelivered: { label = 'Dostarczona'; break; }
                case DELIVERY_DATE_statusDeliveryFailed: { label = 'Nie dostarczona'; break; }
                case DELIVERY_DATE_statusCanceled: { label = 'Anulowana'; break; }
            }
            return label;
        },
        countDeliveries() {
            let counter = 0;
            for( let date in this.order.deliveryDates) {
                if(this.order.deliveryDates[date].omitted == false) {
                    counter++;
                }
            }
            return counter;
        },
        deliveryStatus(deliveryDate){
            let status = 0;
            if(deliveryDate && deliveryDate.products) {
                deliveryDate.products.forEach( 
                    productDelivery  => {
                        if(productDelivery.status)
                            status = productDelivery.status;
                    }
                );
            }
            return status;
        }
    }, 
};
</script>